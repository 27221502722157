import { Sensor, SensorType } from '@services/sensors'

import { Agent } from '@services/agents'
import { DataNode } from 'rc-tree/lib/interface'
import { Device } from '@services/devices'
import { EuiIcon } from '@elastic/eui'
import { Group } from '@services/groups'
import { NetworkTreeNode } from '@components/network'
import moment from 'moment'

export type TreeParams = {
    expandedKeys: string[]
    autoExpandParent: boolean
}

export type TreeMode = 'groups' | 'agents'
export type TreeNode = DataNode & {
    type:
        | 'group'
        | 'agent'
        | 'device'
        | 'sensor'
        | 'wmi_sensors'
        | 'ssh_sensors'
        | 'snmp_sensors'
    data: any
    path?: string
    searchTerm?: string
}

// Charts that will handle data fetching on it's own
export const CUSTOM_CHART_FETCH_TYPES: SensorType[] = [SensorType.SnmpTraffic]

export const ALL_ACTIVATABLE_TREE_NODES = ['sensor', 'device']
export type ActivatableTreeNode = (typeof ALL_ACTIVATABLE_TREE_NODES)[number]

export const ALL_DETAILS_TREE_NODES = ['sensor', 'device', 'group', 'agent']
export type DetailsTreeNode = (typeof ALL_DETAILS_TREE_NODES)[number]
export type TreeState = {
    search?: string | undefined
    errors: boolean
    warnings: boolean
    expandAll: boolean
    sort: boolean
    categories?: number[]
    sessionId?: number | undefined
}

export interface NetworkCollection<T> {
    items: T[]
    errors: number
    warnings: number
}

export const getCustomDateFilter = (
    filter: moment.Moment | string | undefined
) => {
    if (moment.isMoment(filter)) return filter.toDate()
    if (!filter) return undefined
    return new Date(filter)
}

// Cache for tree nodes
const cachedIcons: { [key: string]: JSX.Element } = {}
let cachedNodesKeys: string[] = []

const combineSensorNodes = (
    parentPath: string | undefined,
    sensorType: TreeNode['type'],
    isDeviceEnabled: boolean,
    sensors: Sensor[]
) => {
    const path = parentPath ? `${parentPath}/${sensorType}` : sensorType
    return {
        type: sensorType,
        data: [...sensors],
        path: path,
        title: (
            <NetworkTreeNode
                type={sensorType}
                data={sensors}
                path={path}
                isActive={isDeviceEnabled}
            />
        ),
        key: `${sensorType}_${sensors[0].deviceId}`,
        selectable: false,
    }
}

const getSensorsTreeNodes = (
    sensors: Sensor[],
    isDeviceEnabled: boolean,
    parentPath?: string
): TreeNode[] => {
    const results = []
    const wmiTypes = [
        SensorType.WmiProcessor,
        SensorType.WmiMemory,
        SensorType.WmiDisk,
    ]
    const sshTypes = [
        SensorType.SshProcessor,
        SensorType.SshMemory,
        SensorType.SshDisk,
    ]
    const snmpTypes = [SensorType.SnmpProcessor, SensorType.SnmpTraffic]

    const wmiSensors = sensors.filter((sensor) =>
        wmiTypes.includes(sensor.type)
    )

    if (wmiSensors.length > 0)
        results.push(
            combineSensorNodes(
                parentPath,
                'wmi_sensors',
                isDeviceEnabled,
                wmiSensors
            )
        )

    const sshSensors = sensors.filter((sensor) =>
        sshTypes.includes(sensor.type)
    )

    if (sshSensors.length > 0)
        results.push(
            combineSensorNodes(
                parentPath,
                'ssh_sensors',
                isDeviceEnabled,
                sshSensors
            )
        )

    const snmpSensors = sensors.filter((sensor) =>
        snmpTypes.includes(sensor.type)
    )

    if (snmpSensors.length > 0)
        results.push(
            combineSensorNodes(
                parentPath,
                'snmp_sensors',
                isDeviceEnabled,
                snmpSensors
            )
        )

    const otherSensors = sensors
        .filter(
            (sensor) =>
                !wmiTypes.includes(sensor.type) &&
                !sshTypes.includes(sensor.type) &&
                !snmpTypes.includes(sensor.type)
        )
        .map((sensor) =>
            getSensorTreeNodes(sensor, isDeviceEnabled, parentPath)
        )

    return [...results, ...otherSensors] as TreeNode[]
}

const getSensorTreeNodes = (
    sensor: Sensor,
    isDeviceEnabled: boolean,
    parentPath?: string
): TreeNode => {
    const key = `sensor_${sensor.id}`

    const path = parentPath ? `${parentPath}/${key}` : key
    return {
        key: key,
        title: (
            <NetworkTreeNode
                data={sensor}
                type="sensor"
                path={path}
                isActive={sensor.isEnabled && isDeviceEnabled}
            />
        ),
        type: 'sensor',
        path: path,
        searchTerm: sensor.name.toLowerCase(),
        selectable: false,
        data: { ...sensor },
    }
}

const getDevicesTreeNodes = (
    devices: Device[],
    parentPath?: string
): TreeNode[] => {
    return devices.map((device) => {
        const key = `device_${device.id}`

        if (cachedNodesKeys.indexOf(key) === -1) {
            cachedNodesKeys.push(key)
        }

        const path = parentPath ? `${parentPath}/${key}` : key
        return {
            title: (
                <NetworkTreeNode
                    data={device}
                    type="device"
                    path={path}
                    isActive={device.isEnabled}
                />
            ),
            key: key,
            type: 'device',
            searchTerm: device.name.toLowerCase(),
            path: path,
            selectable: false,
            data: { ...device },
            children: device.sensors
                ? getSensorsTreeNodes(device.sensors, device.isEnabled, path)
                : [],
        }
    })
}

const getGroupTreeNodes = (group: Group, parentPath?: string): TreeNode => {
    const key = `group_${group.id}`

    if (cachedNodesKeys.indexOf(key) === -1) {
        cachedNodesKeys.push(key)
    }

    const path = parentPath ? `${parentPath}/${key}` : key
    const subGroups = group.subGroups
        ? group.subGroups.map((subGroup) => getGroupTreeNodes(subGroup, path))
        : []
    const devices = group.devices
        ? getDevicesTreeNodes(group.devices, path)
        : []

    return {
        key: key,
        title: <NetworkTreeNode data={group} type="group" path={path} />,
        type: 'group',
        path: path,
        searchTerm: group.name.toLowerCase(),
        selectable: false,
        data: { ...group },
        children: [...subGroups, ...devices],
    }
}

const getAgentTreeNodes = (agent: Agent): TreeNode => {
    const key = `agent_${agent.id}`

    if (cachedNodesKeys.indexOf(key) === -1) {
        cachedNodesKeys.push(key)
    }

    return {
        key: key,
        title: (
            <NetworkTreeNode
                data={agent}
                type="agent"
                isActive={agent.isEnabled}
                path={key}
            />
        ),
        type: 'agent',
        path: key,
        searchTerm: agent.name.toLowerCase(),
        selectable: false,
        data: { ...agent },
        children: agent.devices ? getDevicesTreeNodes(agent.devices, key) : [],
    }
}

export const getTreeNodes = (data: any[], treeMode: TreeMode): TreeNode[] => {
    // Reset cache
    cachedNodesKeys = []

    switch (treeMode) {
        case 'groups':
            return data.map((group) => getGroupTreeNodes(group))
        case 'agents':
            return data.map((agent) => getAgentTreeNodes(agent))
        default:
            return []
    }
}

/**
 * Gets all keys of the nodes in the tree
 * @param data The tree data
 * @note This method has been refactored to used a cached array to improve performance
 * @returns An array of keys
 */
export const getAllNodesKeys = (data: TreeNode[]) => cachedNodesKeys

const generateNodeIcon = (type: string) => {
    const iconStyle = { paddingRight: 4, display: 'flex' }

    switch (type) {
        case 'group':
            return <EuiIcon style={iconStyle} type="folderClosed" />
        case 'agent':
            return <EuiIcon style={iconStyle} type="reporter" />
        case 'device':
            return <EuiIcon style={iconStyle} type="desktop" />
        case 'wmi_sensors':
        case 'ssh_sensors':
        case 'snmp_sensors':
        case 'sensor':
            return <EuiIcon style={iconStyle} type="visGauge" />
        default:
            return <span></span>
    }
}

export const getNodeIcon = (type: string) => {
    if (!cachedIcons[type]) {
        cachedIcons[type] = generateNodeIcon(type)
    }
    return cachedIcons[type]
}

export const encodeUrlTreeState = (state: TreeState) => {
    const parts: string[] = []

    Object.keys(state).forEach((key) => {
        const value = state[key as keyof TreeState] as any

        // Check if the value is an array
        if (Array.isArray(value)) {
            value.forEach((v) => {
                parts.push(`${key}=${v}`)
            })
            return
        }

        parts.push(`${key}=${value.toString()}`)
    })

    return parts.join('&')
}
