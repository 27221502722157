import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_TIME_FORMAT } from '@utils/dates'
import {
    EuiAvatar,
    EuiBadge,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiText,
    EuiTimelineItem,
    IconType,
} from '@elastic/eui'
import { useEffect, useState } from 'react'

import { DataPointExport } from '@services/exports'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type ExportTimelineDetailsProps = {
    handleOnDelete: (id: string) => Promise<void>
    handleOnReschedule: (id: string) => Promise<void>
    handleDownloadRequest: (id: string) => void
    dataPointExport: DataPointExport
}

const ExportTimelineDetails = ({
    dataPointExport,
    handleOnDelete,
    handleOnReschedule,
    handleDownloadRequest,
}: ExportTimelineDetailsProps) => {
    const [avatar, setAvatar] = useState<JSX.Element>()
    const [badge, setBadge] = useState<JSX.Element>()
    const [button, setButton] = useState<JSX.Element>()
    const [additionalInfo, setAdditionalInfo] = useState<JSX.Element>()
    const [content, setContent] = useState<string>()
    const [nodeName, setNodeName] = useState<string>()
    const [showContent, setShowContent] = useState<boolean>(false)

    const { t } = useTranslation(['common', 'exports'])

    useEffect(() => {
        let iconType
        let color
        let backgroundColor

        switch (dataPointExport.status) {
            case 'Scheduled':
                iconType = 'pause'
                backgroundColor = '#F1F4FA'
                break
            case 'InProgress':
                iconType = 'playFilled'
                color = '#F7F8FC'
                backgroundColor = '#6092C0'
                break
            case 'Completed':
                iconType = 'check'
                color = '#F7F8FC'
                backgroundColor = '#54B399'
                break
            case 'Cancelled':
                iconType = 'stopFilled'
                color = '#F7F8FC'
                backgroundColor = '#D6BF57'
                break
            case 'Failed':
                iconType = 'error'
                color = '#F7F8FC'
                backgroundColor = '#E7664C'
                break
            default:
                break
        }

        if (dataPointExport.status === 'Scheduled')
            setButton(
                <EuiButtonIcon
                    size="xs"
                    onClick={() => handleOnDelete(dataPointExport.id)}
                    iconType="trash"
                    color="danger"
                    iconSize="m"
                />
            )

        if (
            dataPointExport.status === 'InProgress' &&
            dataPointExport.additionalInfo?.exportStartedAt
        )
            setAdditionalInfo(
                <EuiFlexItem grow={false}>
                    <EuiText size="xs">
                        <i>
                            {t('exports:started_at', {
                                time: moment(
                                    dataPointExport.additionalInfo
                                        .exportStartedAt
                                ).format(DEFAULT_TIME_FORMAT),
                            })}
                        </i>
                    </EuiText>
                </EuiFlexItem>
            )

        if (dataPointExport.status === 'Completed') {
            setButton(
                <EuiButtonIcon
                    size="xs"
                    onClick={() => handleDownloadRequest(dataPointExport.id)}
                    iconType="download"
                    iconSize="m"
                />
            )
            if (
                dataPointExport.additionalInfo?.exportStartedAt &&
                dataPointExport.additionalInfo.exportFinishedAt
            ) {
                const diff = moment.duration(
                    moment(
                        dataPointExport.additionalInfo.exportFinishedAt
                    ).diff(
                        moment(dataPointExport.additionalInfo.exportStartedAt)
                    )
                )

                setAdditionalInfo(
                    <EuiFlexItem grow={false}>
                        <EuiText size="xs">
                            <i>
                                {t('exports:finished_in', {
                                    timespan: moment
                                        .utc(diff.asMilliseconds())
                                        .format('HH:mm:ss:SSS'),
                                })}
                            </i>
                        </EuiText>
                    </EuiFlexItem>
                )
            }
        }

        if (dataPointExport.status === 'Cancelled')
            setButton(
                <EuiButtonIcon
                    size="xs"
                    onClick={() => handleOnReschedule(dataPointExport.id)}
                    iconType="playFilled"
                    iconSize="m"
                />
            )

        if (dataPointExport.status === 'Failed') {
            setButton(
                <EuiButtonIcon
                    size="xs"
                    onClick={() => setShowContent(!showContent)}
                    iconType={showContent ? 'arrowDown' : 'arrowRight'}
                    iconSize="m"
                />
            )

            setContent(
                dataPointExport.additionalInfo?.errorMessage ||
                    'Internal server error'
            )
        }

        setAvatar(
            <EuiAvatar
                name="status"
                iconType={iconType as IconType}
                color={backgroundColor}
                iconColor={color}
            />
        )
        setBadge(
            <EuiBadge
                color={backgroundColor}
                iconType={iconType}
                style={{ color: color }}
            >
                {t(`exports:status:${dataPointExport.status}`)}
            </EuiBadge>
        )
        setNodeName(
            `${dataPointExport.additionalInfo?.deviceName || ''}${dataPointExport.additionalInfo?.sensorName ? ` - ${dataPointExport.additionalInfo.sensorName}` : ''}`
        )
    }, [dataPointExport, showContent])

    return (
        <>
            <EuiTimelineItem icon={avatar}>
                <EuiFlexGroup alignItems="center" gutterSize="s">
                    <EuiFlexItem grow={false}>
                        <EuiText size="xs">
                            <span>
                                {moment(dataPointExport.timeStamp).format(
                                    DEFAULT_DATE_TIME_FORMAT
                                )}
                            </span>
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>{badge}</EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiText size="xs">{nodeName}</EuiText>
                    </EuiFlexItem>
                    {additionalInfo && additionalInfo}
                    <EuiFlexItem />
                    {button && <EuiFlexItem grow={false}>{button}</EuiFlexItem>}
                </EuiFlexGroup>
                {showContent && (
                    <>
                        <EuiHorizontalRule margin="s" />
                        <EuiFlexGroup alignItems="center">
                            <EuiFlexItem>
                                <EuiText size="xs">{content}</EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                )}
            </EuiTimelineItem>
        </>
    )
}

export default ExportTimelineDetails
