export const saveFileInBrowser = (blob: Blob, filename?: string | null) => {
    const url = window.URL || window.webkitURL
    const href = url.createObjectURL(blob)
    const hiddenElement = document.createElement('a')

    hiddenElement.href = href
    hiddenElement.target = '_blank'
    hiddenElement.download = filename ?? (blob as File).name

    hiddenElement.click()
    hiddenElement.remove()
}
