import 'moment/locale/nl'

import {
    AgentDebugPage,
    AgentDetailsPage,
    AgentInstallPage,
    AgentsPage,
} from '@pages/agents'
import { ApiKeyDetailsPage, ApiKeysPage } from '@pages/apiKeys'
import { AuthRoute, Layout } from '@components/layout'
import { CategoriesPage, CategoryDetailsPage } from '@pages/categories'
import { DeviceDetailsPage, DevicesPage } from '@pages/devices'
import {
    DeviceTemplateDetailsPage,
    DeviceTemplatesPage,
} from '@pages/deviceTemplates'
import {
    EuiGlobalToastList,
    EuiProvider,
    EuiThemeColorMode,
} from '@elastic/eui'
import {
    ExportDetailsPage,
    GroupDetailsPage,
    NetworkPage,
} from '@pages/network'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { RoleDetailsPage, RolesPage } from '@pages/roles'
import { SensorDetailsPage, SensorsPage } from '@pages/sensors'
import {
    SensorTemplateDetailsPage,
    SensorTemplatesPage,
} from '@pages/sensorTemplates'
import { Suspense, lazy, useEffect, useMemo } from 'react'
import { TenantDetailsPage, TenantsPage } from '@pages/tenants'
import { UserDetailsPage, UsersPage } from '@pages/users'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import { AlarmDetailsPage } from '@pages/alarms'
import AlarmsPage from '@pages/alarms/AlarmsPage'
import { DashboardPage } from '@pages/dashboards'
import { I18nextProvider } from 'react-i18next'
import { LoginPage } from '@pages/auth'
import { PageNotFound } from '@pages/common'
import { Permissions } from '@services/auth'
import { SensorTroubleshootingConfigurator } from '@components/sensors'
import { THEME } from '@config/theme'
import i18n from '@locale/i18n'
import moment from 'moment'
import { removeToast } from '@store/toasts'
import { useHasPermission } from '@hooks/auth'

moment.locale('nl')

const colorMode: EuiThemeColorMode =
    (localStorage.getItem('theme') as EuiThemeColorMode) ?? 'light'

// Register color modes
const DarkTheme = lazy(() => import('./components/colorModes/DarkTheme'))
const LightTheme = lazy(() => import('./components/colorModes/LightTheme'))

const App = () => {
    const { toasts } = useAppSelector((state) => state.toasts)
    const { navigateTo } = useAppSelector((state) => state.main)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const canViewAlarms = useHasPermission(Permissions.alarm.canView)
    const canViewNetwork = useHasPermission(Permissions.network.canView)

    const defaultRoute = useMemo(() => {
        if (canViewAlarms && !canViewNetwork) return '/alarms'

        return '/network'
    }, [canViewAlarms, canViewNetwork])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dismissToast = (toast: any) => {
        dispatch(removeToast(toast.id))
    }

    useEffect(() => {
        if (navigateTo) {
            navigate(navigateTo)
        }
    }, [navigateTo])

    return (
        <I18nextProvider i18n={i18n}>
            <EuiProvider colorMode={colorMode} modify={THEME}>
                <Suspense fallback={<></>}>
                    {colorMode === 'dark' ? <DarkTheme /> : <LightTheme />}
                </Suspense>
                <Routes>
                    <Route
                        path="dashboards"
                        element={<DashboardPage />}
                    ></Route>
                    <Route path="/auth/login" element={<LoginPage />} />
                    <Route path="/" element={<AuthRoute />}>
                        <Route path="/" element={<Layout />}>
                            <Route path="network" element={<NetworkPage />}>
                                <Route path="group/:id">
                                    <Route
                                        index
                                        element={<GroupDetailsPage />}
                                    />
                                    <Route
                                        path=":parentGroup"
                                        element={<GroupDetailsPage />}
                                    />
                                </Route>
                                <Route
                                    path="agent/:id"
                                    element={<AgentDetailsPage />}
                                />
                                <Route
                                    path="device/:id"
                                    element={<DeviceDetailsPage />}
                                />
                                <Route
                                    path="sensor/:id"
                                    element={<SensorDetailsPage />}
                                />
                                <Route
                                    path="sensor/:id/troubleshoot"
                                    element={
                                        <SensorTroubleshootingConfigurator />
                                    }
                                />
                                <Route
                                    path="agent/:id/install"
                                    element={<AgentInstallPage />}
                                />
                                <Route
                                    path="export"
                                    element={<ExportDetailsPage />}
                                />
                            </Route>
                            <Route path="alarms" element={<AlarmsPage />}>
                                <Route
                                    path=":id"
                                    element={<AlarmDetailsPage />}
                                />
                            </Route>
                            <Route path="tenants" element={<TenantsPage />}>
                                <Route
                                    path=":id"
                                    element={<TenantDetailsPage />}
                                />
                            </Route>
                            <Route path="agents" element={<AgentsPage />}>
                                <Route
                                    path=":id"
                                    element={<AgentDetailsPage />}
                                />
                                <Route
                                    path=":id/install"
                                    element={<AgentInstallPage />}
                                />
                                <Route
                                    path=":id/debug"
                                    element={<AgentDebugPage />}
                                />
                            </Route>
                            <Route path="devices" element={<DevicesPage />}>
                                <Route
                                    path=":id"
                                    element={<DeviceDetailsPage />}
                                />
                            </Route>
                            <Route
                                path="deviceTemplates"
                                element={<DeviceTemplatesPage />}
                            >
                                <Route
                                    path=":id"
                                    element={<DeviceTemplateDetailsPage />}
                                />
                            </Route>
                            <Route
                                path="categories"
                                element={<CategoriesPage />}
                            >
                                <Route
                                    path=":id"
                                    element={<CategoryDetailsPage />}
                                />
                            </Route>
                            <Route path="sensors" element={<SensorsPage />}>
                                <Route
                                    path=":id"
                                    element={<SensorDetailsPage />}
                                />
                            </Route>
                            <Route
                                path="sensorTemplates"
                                element={<SensorTemplatesPage />}
                            >
                                <Route
                                    path=":id"
                                    element={<SensorTemplateDetailsPage />}
                                />
                            </Route>
                            <Route path="users" element={<UsersPage />}>
                                <Route
                                    path=":id"
                                    element={<UserDetailsPage />}
                                />
                            </Route>
                            <Route path="roles" element={<RolesPage />}>
                                <Route
                                    path=":id"
                                    element={<RoleDetailsPage />}
                                />
                            </Route>
                            <Route path="api-keys" element={<ApiKeysPage />}>
                                <Route
                                    path="new"
                                    element={<ApiKeyDetailsPage />}
                                />
                            </Route>

                            {/* Default route to be used as home page */}
                            <Route
                                path="/"
                                element={<Navigate to={defaultRoute} />}
                            />

                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                    </Route>
                </Routes>
                <EuiGlobalToastList
                    toasts={toasts}
                    dismissToast={dismissToast}
                    toastLifeTimeMs={6000}
                />
            </EuiProvider>
        </I18nextProvider>
    )
}

export default App
