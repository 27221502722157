import {
    EuiButton,
    EuiButtonIcon,
    EuiConfirmModal,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTimeline,
} from '@elastic/eui'
import {
    useDeleteExportMutation,
    useDownloadExportQuery,
    useFetchExportsQuery,
    useRequestExportMutation,
    useRescheduleExportMutation,
} from '@services/api'
import { useEffect, useState } from 'react'

import { DetailsPage } from '@components/layout'
import { Device } from '@services/devices'
import ExportTimelineDetails from '@components/exports/ExportTimelineDetails'
import { getCustomDateFilter } from '@services/network'
import { useAppSelector } from '@hooks/store'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ExportDetailsPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(['exports', 'common'])
    const state = useAppSelector((state) => state.network)
    const [timelineDetails, setTimelineDetails] = useState<JSX.Element[]>([])
    const [deleteConfirm, setDeleteConfirm] = useState<string | undefined>(
        undefined
    )
    const [downloadRequest, setDownloadRequest] = useState<string | undefined>(
        undefined
    )

    const { data, refetch, isFetching: isFetching } = useFetchExportsQuery()

    const { isLoading: isDownloading } = useDownloadExportQuery(
        downloadRequest || '',
        { skip: !downloadRequest }
    )

    const [requestExport, { isLoading }] = useRequestExportMutation()
    const [deleteExport, { isLoading: isDeleting }] = useDeleteExportMutation()
    const [rescheduleExport, { isLoading: isRescheduling }] =
        useRescheduleExportMutation()

    const handleOnRequest = async () => {
        const query = {
            type: state.dateFilter,
            start: getCustomDateFilter(state.customDateFilter?.[0]),
            end: getCustomDateFilter(state.customDateFilter?.[1]),
        }

        let sensorIds: number[] = []
        switch (state.activeNode?.type) {
            case 'sensor':
                sensorIds = [state.activeNode.data.id]
                break
            case 'device':
                sensorIds =
                    (state.activeNode.data as Device).sensors?.map(
                        (x) => x.id
                    ) || []
                break
            default:
                break
        }

        const requestId = await requestExport({ query, sensorIds })
        navigate('/network/export')
    }

    const handleOnDelete = async (id: string) => {
        await deleteExport(id)
        navigate('/network/export')
    }

    const handleOnReschedule = async (id: string) => {
        await rescheduleExport(id)
        navigate('/network/export')
    }

    const handleDownloadRequest = (id: string) => {
        setDownloadRequest(id)
    }

    useEffect(() => {
        if (!isDownloading && downloadRequest != undefined)
            setDownloadRequest(undefined)
    }, [isDownloading])

    useEffect(() => {
        if (data)
            setTimelineDetails(
                data.map((exp) => (
                    <ExportTimelineDetails
                        key={`timeline-details-${exp.id}`}
                        dataPointExport={exp}
                        handleOnDelete={handleOnDelete}
                        handleOnReschedule={handleOnReschedule}
                        handleDownloadRequest={handleDownloadRequest}
                    />
                ))
            )
        else setTimelineDetails([])
    }, [data])

    return (
        <>
            {!!deleteConfirm && (
                <EuiConfirmModal
                    title={t('common:delete_confirm_title')}
                    onCancel={() => setDeleteConfirm(undefined)}
                    onConfirm={() => {
                        handleOnDelete(deleteConfirm)
                        setDeleteConfirm(undefined)
                    }}
                    cancelButtonText={t('common:delete_confirm_cancel')}
                    confirmButtonText={t('common:delete_confirm_ok')}
                    buttonColor="danger"
                    defaultFocusedButton="confirm"
                    isLoading={isDeleting}
                >
                    <p>{t('common:delete_confirm_description')}</p>
                </EuiConfirmModal>
            )}

            <DetailsPage
                onClose={() => navigate('/network')}
                size="s"
                title={t('exports:page_title')}
                loading={
                    isFetching || isDeleting || isRescheduling || isDownloading
                }
            >
                <EuiFlexGroup
                    direction="column"
                    justifyContent="spaceBetween"
                    alignItems="stretch"
                    style={{ height: '100%' }}
                >
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup
                            justifyContent="flexEnd"
                            alignItems="flexEnd"
                        >
                            <EuiFlexItem grow={false}>
                                <EuiButtonIcon
                                    iconType="refresh"
                                    size="s"
                                    onClick={() => refetch()}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton
                                    color="primary"
                                    fill
                                    size="s"
                                    iconType="exportAction"
                                    isLoading={isLoading}
                                    onClick={handleOnRequest}
                                >
                                    {t('exports:export_data')}
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiTimeline>{timelineDetails}</EuiTimeline>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </DetailsPage>
        </>
    )
}

export default ExportDetailsPage
