import {
    EuiBadge,
    EuiBeacon,
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHealth,
    EuiSplitPanel,
    EuiToolTip,
} from '@elastic/eui'
import {
    NetworkDateFilter,
    NetworkTree,
    NetworkViewer,
} from '@components/network'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useMemo, useState } from 'react'

import { DateFilterType } from '@services/data'
import { Device } from '@services/devices'
import { Page } from '@components/layout'
import { Sensor } from '@services/sensors'
import { formatFullDate } from '@utils/dates'
import { getCustomDateFilter } from '@services/network'
import moment from 'moment'
import { setDateFilter } from '@store/network'
import { useTranslation } from 'react-i18next'

type NetworkViewersProps = {
    onUpdate?: (lastUpdatedTimestamp: number) => void
}

const NetworkViewers = (props: NetworkViewersProps) => {
    const state = useAppSelector((state) => state.network)
    const query = {
        type: state.dateFilter,
        start: getCustomDateFilter(state.customDateFilter?.[0]),
        end: getCustomDateFilter(state.customDateFilter?.[1]),
    }

    // No active node selected
    if (!state.activeNode) {
        return <div>NO ACTIVE NODE</div>
    }

    // Sensor
    if (state.activeNode.type === 'sensor') {
        return (
            <NetworkViewer
                sensor={state.activeNode.data as Sensor}
                query={query}
                onUpdate={props.onUpdate}
            />
        )
    }

    // Device
    if (state.activeNode.type === 'device') {
        return (
            <>
                {(state.activeNode.data as Device).sensors?.map((sensor) => (
                    <NetworkViewer
                        key={sensor.id}
                        sensor={sensor}
                        query={query}
                        onUpdate={props.onUpdate}
                    />
                ))}
            </>
        )
    }

    console.error('Unsupported node type:', state.activeNode?.type)
    return <div></div>
}

const NetworkPage = () => {
    const state = useAppSelector((state) => state.network)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [lastUpdated, setLastUpdated] = useState<Date>(new Date())
    const { t } = useTranslation(['sensors', 'network'])

    const onNetworkViewerUpdate = (lastUpdatedTimestamp: number) => {
        const newDate = moment(lastUpdatedTimestamp).toDate()
        setLastUpdated(newDate)
    }

    const lastUpdatedText = useMemo(
        () => moment(lastUpdated).format('HH:mm:ss'),
        [lastUpdated]
    )

    const networkViewers = useMemo(
        () => <NetworkViewers onUpdate={onNetworkViewerUpdate} />,
        [state.activeNode, state.dateFilter]
    )

    const onDateFilterChange = (type: DateFilterType) => {
        dispatch(setDateFilter(type))
    }

    const pageItems = useMemo(() => {
        const items = [
            <EuiButton
                iconType={'exportAction'}
                size="s"
                key="export-data"
                onClick={() => navigate('/network/export')}
            >
                {t('network:export')}
            </EuiButton>,
            <NetworkDateFilter
                selectedFilter={state.dateFilter}
                onChange={onDateFilterChange}
                key="date-filter"
            />,
            <span key="last-updated-text">
                <EuiHealth color="success">
                    {t('network:last_updated', {
                        date: lastUpdatedText,
                    })}
                </EuiHealth>
            </span>,
        ]

        if ((state.activeNode?.data as Sensor)?.isPaused) {
            const sensor = state.activeNode?.data as Sensor
            items.push(
                <EuiToolTip
                    content={
                        sensor.isPaused && sensor.pausedUntil
                            ? t('sensors:paused_until', {
                                  date: formatFullDate(sensor.pausedUntil),
                              })
                            : undefined
                    }
                >
                    <EuiBadge
                        key="pause-badge"
                        color="primary"
                        iconType="pause"
                    >
                        {t('network:paused')}
                    </EuiBadge>
                </EuiToolTip>
            )
        }

        return items
    }, [state.dateFilter, lastUpdatedText, state.activeNode])

    return (
        <>
            <Outlet />
            <Page title={t('network:title')} fullWidth iconType="visualizeApp">
                <EuiSplitPanel.Outer
                    direction="row"
                    hasBorder={false}
                    hasShadow={false}
                    grow={true}
                >
                    <EuiSplitPanel.Inner color="subdued" grow={false}>
                        <NetworkTree />
                    </EuiSplitPanel.Inner>
                    <EuiSplitPanel.Inner>
                        <Page
                            title={
                                <>
                                    <EuiFlexGroup
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <EuiFlexItem grow={false}>
                                            {(state.activeNode?.data as any)
                                                ?.isTroubleshooting && (
                                                <EuiToolTip
                                                    content={t(
                                                        'sensors:sensor_in_troubleshooting_mode'
                                                    )}
                                                >
                                                    <EuiBeacon />
                                                </EuiToolTip>
                                            )}
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <span>
                                                {state.activeNode?.data?.name}
                                            </span>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </>
                            }
                            fullWidth
                            isSubPage={true}
                            items={pageItems}
                        >
                            {networkViewers}
                        </Page>
                    </EuiSplitPanel.Inner>
                </EuiSplitPanel.Outer>
            </Page>
        </>
    )
}
export default NetworkPage
