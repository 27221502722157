import {
    DataPoint,
    SensorDataRequest,
    encodeUrlDataQuery,
} from '@services/data'
import { DataPointExport, DataPointExportRequest } from '@services/exports'
import {
    NetworkCollection,
    TreeState,
    encodeUrlTreeState,
} from '@services/network'

import { Agent } from '@services/agents'
import { Group } from '@services/groups'
import { appApi } from '../app.api'
import { saveFileInBrowser } from '@utils/files'

const networkApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchNetworkAgents: builder.query<NetworkCollection<Agent>, TreeState>({
            providesTags: ['AgentNetworkTree'],
            query: (q) => `/network/agents?${encodeUrlTreeState(q)}`,
        }),
        fetchNetworkGroups: builder.query<NetworkCollection<Group>, TreeState>({
            providesTags: ['GroupNetworkTree'],
            query: (q) => `/network/groups?${encodeUrlTreeState(q)}`,
        }),
        fetchNetworkSensorData: builder.query<DataPoint[], SensorDataRequest>({
            query: (q) =>
                `/sensors/${q.sensor}/data?${encodeUrlDataQuery(q.query)}`,
        }),
        fetchNetworkSensorSnapshot: builder.query<DataPoint, number>({
            query: (sensorId) => `/sensors/${sensorId}/snapshot`,
        }),
        fetchExports: builder.query<DataPointExport[], void>({
            query: () => ({
                url: 'network/export',
                method: 'GET',
            }),
            providesTags: ['Exports'],
        }),
        downloadExport: builder.query<void, string>({
            query: (id) => ({
                url: `network/export/${id}`,
                method: 'GET',
                responseHandler: async (response) => {
                    const blob = await response.blob()
                    if (blob) {
                        saveFileInBrowser(blob, id)
                    }
                },
            }),
        }),
        requestExport: builder.mutation<string, DataPointExportRequest>({
            query: (body) => ({
                url: 'network/export',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Exports'],
        }),
        rescheduleExport: builder.mutation<void, string>({
            query: (id) => ({
                url: `network/export/reschedule/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Exports'],
        }),
        deleteExport: builder.mutation<void, string>({
            query: (id) => ({
                url: `network/export/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Exports'],
        }),
    }),
})

export const {
    useFetchNetworkAgentsQuery,
    useFetchNetworkGroupsQuery,
    useFetchNetworkSensorDataQuery,
    useFetchNetworkSensorSnapshotQuery,
    useFetchExportsQuery,
    useRequestExportMutation,
    useDeleteExportMutation,
    useRescheduleExportMutation,
    useDownloadExportQuery,
} = networkApi
